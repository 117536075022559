import moment from 'moment';
import 'owl.carousel';
import 'magnific-popup';
import Cookies from 'js-cookie';

jQuery( document ).ready( function ( $ ) {
	$.magnificPopup.open( {
		items: {
			src: '#tour-banner',
			type: 'inline'
		}
	} );
	// if ( Cookies.get( 'provident_crm2' ) !== 'viewed' ) {
	// 	$.magnificPopup.open( {
	// 		items: {
	// 			src: '#tour-banner',
	// 			type: 'inline'
	// 		}
	// 	} );

	// 	// Cookies.set( 'provident_crm2', 'viewed', { expires: 1 } );
	// }
	// Nav
	$( '.menu>li>a.local' ).on( 'click', function ( e ) {
		e.preventDefault();
		const href = $( this ).attr( 'href' );

		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1500 );
	} );

	// Music
	$( '#music .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		dots: false,
		autoHeight: true
	} );

	// Videos
	$( '#videos .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		dots: false,
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2,
				margin: 30
			}
		}
	} );

	// Tour
	$( '#load-more-dates' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#events>div' ).fadeIn();
		$( this ).fadeOut();
	} );

	/*$.magnificPopup.open( {
		items: {
			src: '#tour-banner',
			type: 'inline'
		}
	} );*/

	// $( '#tour-banner .btn' ).on( 'click', function ( e ) {
	// 	e.preventDefault();
	// 	$.magnificPopup.close();
	// } );

	// $.ajax( {
	// 	url: 'https://rest.bandsintown.com/artists/id_2584/events?app_id=45PRESS_CASTING_CROWNS',
	// 	method: 'GET',
	// 	dataType: 'json',
	// 	xhrFields: {
	// 		withCredentials: false
	// 	},
	// 	error: () => {
	// 	},
	// 	success: data => {
	// 		const events = $( '#events' );
	// 		let html = '';

	// 		if ( data.length ) {
	// 			if ( data.length <= 12 ) {
	// 				$( '#load-more-dates' ).hide();
	// 			}

	// 			for ( let event of data ) {
	// 				html += '<div data-bit-id="' + event.id + '">';
	// 				html += '<div class="event-date">' + moment( event.datetime ).format( 'ddd, MMM DD' ) + '</div>';
	// 				html += '<div class="event-venue">' + event.venue.name + '</div>';
	// 				html += '<div class="event-location">' + event.venue.location + '</div>';
	// 				html += '<div class="event-links">';
	// 				//html += '<a href="' + event.url + '" target="_blank" class="rsvp">RSVP</a><br>';
	// 				if ( event.offers ) {
	// 					for ( let offer of event.offers ) {
	// 						html += '<a href="' + offer.url + '" target="_blank" class="btn">Tickets</a>';
	// 						break;
	// 					}
	// 				}

	// 				html += '</div>';
	// 				html += '</div>';
	// 			}
	// 			events.html( html );
	// 		} else {
	// 			events.html( 'No upcoming events.' );
	// 		}
	// 	}
	// } );

	// About
	$( '.popup-trigger' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );
	$( '.mfp-image' ).magnificPopup( {
		type: 'image'
	} );
} );
